@import url(https://fonts.googleapis.com/css?family=Open+Sans:300&display=swap" rel="stylesheet);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --height: 445px;
  --width: 340px;
  --small-text: .9rem;
  --med-text: 1.7rem;
  --large-text: 2.5rem;
  --text-color: #5f6775;
}

* {
  font-family: 'Open Sans', sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  height: 445px;
  height: var(--height);
  width: 340px;
  width: var(--width);
  border-radius: .105rem;
  padding: 1rem;
}

.intro {
  display: flex;
  justify-content: left;
  padding: .3rem 1rem 0 2rem;
}

.package__title {
  font-size: 1.7rem;
  font-size: var(--med-text);
  background: -webkit-linear-gradient(-45deg, #00ff95, #00ffdd 10%, #00b7ff 25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.package__desciption {
  font-size: .9rem;
  font-size: var(--small-text);
  color: #5f6775;
  color: var(--text-color);
  margin-top: -1.2rem;
}

.total__pricing {
  display: flex;
  align-items: baseline;
}

.package__price {
  font-size: 2.5rem;
  font-size: var(--large-text);
  padding: 0 .5rem 0 0;
  color: #fff;
}

.package__term {
  font-size: .9rem;
  font-size: var(--small-text);
  color: #5f6775;
  color: var(--text-color);
}

.contents {
  display: flex;
  justify-content: left;
  padding: 0 0 0 1rem;
  margin-top: -.25rem;
}

.features__list {
  list-style: none;
  padding-left: 1.2em;
  font-size: .9rem;
  font-size: var(--small-text);
  color: #5f6775;
  color: var(--text-color);
}

.feature__item {
  padding: .15rem 0;

}

.action {
  display: flex;
  justify-content: right;
  margin: 1rem 2.6rem 0 0;
}

.cta__round {
  background: -webkit-linear-gradient(-45deg, #00ff95 10%, #00ffdd 20%, #00b7ff 70%);
  padding: 1rem;
  border-radius: 2rem;
  overflow: hidden;
  width: 1.3rem;
  height: 1.3rem;
}

.cta__round:hover {
  -webkit-transform: translate(.35rem);
          transform: translate(.35rem);
  box-shadow: -.3125rem 0 .5rem rgba(0, 0, 0, 0.3);
  transition: .3s ease-out;
  position: relative;
}

.cta__round:hover #arrow {
  -webkit-transform: translate(4rem);
          transform: translate(4rem);
  opacity: 0;
  transition: .3s;
}

.cta__round:hover #arrow_hidden {
  transition: .3s .15s;
  left: 1rem;
}

.arrow {
  display: inline-flex;
  align-items: center;
  width: 1.3rem;
  height: auto;
}

#arrow_hidden {
  position: absolute;
  left: -4rem;
  width: 1.3rem;
  height: auto;
}
